import React from 'react'
import * as UI from '@/components/UI'
import {
  Grid,
  Cell,
  Section,
  Title,
  Description,
  List,
  Item,
  Link,
} from './Message.css'

const Message = ({ content }) => (
  <UI.Container>
    <Grid>
      <Cell>
        <Section>
          <Title>{content.bottom_title}</Title>
          <Description>{content.bottom_description}</Description>
          <List>
            {content.bottom_links.map(({ text, link }) => (
              <Item key={link}>
                <Link to={link}>{text}</Link>
              </Item>
            ))}
          </List>
        </Section>
      </Cell>
    </Grid>
  </UI.Container>
)

export default Message
