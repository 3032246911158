const metaImage = content => {
  if (
    content.meta_image.localFile === null ||
    content.meta_image.localFile === undefined
  ) {
    return null
  }
  return content.meta_image.localFile.childImageSharp.fixed.src
}

export default metaImage
