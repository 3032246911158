import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Grid = styled(UI.Grid)`
  justify-content: center;
`

export const Cell = styled.div`
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(10)};
  `}
  ${above('lg')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const Section = styled.section`
  position: relative;
  z-index: 1;
  padding: 1.5rem;
  background: ${p => p.theme.colors.flash};
  margin-bottom: 7.5rem;
  ${above('md')`
    margin-bottom: 8.75rem;
  `}
  ${above('lg')`
    margin-bottom: 10rem;
  `}
`

export const Title = styled.h2`
  ${getTypeStyle('blogText')};
  font-family: ${p => p.theme.type.fonts.headline};
  opacity: 0.8;
  margin-bottom: 0.5rem;
`

export const Description = styled.p`
  ${getTypeStyle('smallText')};
  color: ${p => p.theme.colors.gray};
  margin-bottom: 1rem;
`

export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const Item = styled.li`
  ${getTypeStyle('text')};
  &:first-of-type:after {
    content: '•';
    color: ${p => p.theme.colors.black};
    font-size: 14px;
    line-height: 22px;
    padding: 0.5rem;
  }
`

export const Link = styled(UI.Link)``
