import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Contact from '@/components/contents/ContactIndex/Contact'
import Message from '@/components/contents/ContactIndex/Message'
import {
  InnerWrapper,
  StyledImage,
} from '@/components/contents/ContactIndex/Contact.css'
import * as UI from '@/components/UI'
import cloud from '@/assets/images/cloud.png'
import cheetosCircle from '@/assets/images/cheetos-circle.png'
import heart from '@/assets/images/heart.png'
import getMetaImage from '@/utils/getMetaImage'

const ContactPage = ({ data }) => {
  const content = data?.content?.data

  const metaImage = getMetaImage(content)
  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <InnerWrapper>
        <UI.Parallax top='6%' right='20%' force={1.1} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={cloud}
              alt={content.meta_title}
              width='287'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax top='20%' left='10%' force={1.0} offsetComp={10}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={heart}
              alt={content.meta_title}
              width='211'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <Contact content={content} />
      </InnerWrapper>
      <InnerWrapper>
        <Message content={content} />
        <StyledImage src={cheetosCircle} alt={content.meta_title} width='545' />
      </InnerWrapper>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPage($locale: String) {
    content: prismicContactPage(lang: { eq: $locale }) {
      lang
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        bottom_title
        bottom_description
        bottom_links {
          text
          link {
            url
          }
        }
      }
    }
  }
`

export default ContactPage
